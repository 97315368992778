import { load, save } from "@services/local-storage"
import qs from "querystringify"
import { v4 as uuidv4 } from "uuid"

const GoogleShopping = ({ location }) => {
  const searchParams = location?.search ? qs.parse(location?.search) : {}
  const googleShopping = !!searchParams.uk
  const gclid = searchParams.gclid
  const loadedGoogleShopping = load("googleShopping")

  const customerId = load("customerId")
  if (!customerId) {
    const customerId = uuidv4()
    save("customerId", customerId)
  }

  if (googleShopping) {
    save("googleShopping", {
      enabled: true,
      date: new Date().toISOString().split("T")[0], // Gets the YYYY-MM-DD format
    })
  }
  if (loadedGoogleShopping) {
    const currentDate = new Date()
    const loadedDate = new Date(loadedGoogleShopping.date)
    const diff = (currentDate - loadedDate) / (1000 * 60 * 60 * 24) // Calculate days difference

    if (diff > 5) {
      save("googleShopping", {
        enabled: googleShopping,
        date: new Date().toISOString().split("T")[0],
      })
    }
  }

  if (gclid) {
    save("gclid", gclid)
  }
}

export default GoogleShopping
